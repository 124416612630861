import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { ThNavHeader } from './ThNavHeader';
import { ThNavFooter } from './ThNavFooter';
import ThUserMenu from './ThUserMenu';

import { getClientId } from './utils/analyticsclientIdUtils';
import { encryptData } from './utils/encryptionUtils';
import { API_URL } from './utils/constants';


import Flickity from 'flickity';
import 'flickity/css/flickity.css';

export class ThUserCard extends Component {
    static displayName = ThUserCard.name;
    constructor(props) {
        super(props);
        this.state = {
            userData: null,
            cardList: [],
            cardListActive: [], //only Registered card
            selectedIndex: 0,
            selectedCard: [],
            selectedCardHis: [],
            cardNickName: '',
            cardType: '',
            renderCheck: false
        };
        this.carouselRef = React.createRef();
        this.flickity = null;
    }

    componentDidMount() {
        const userDataFromStorage = sessionStorage.getItem('userData');

        if (userDataFromStorage) {
            const parsedUserData = JSON.parse(userDataFromStorage);
            this.setState({ userData: parsedUserData }, () => {
                if (this.state.userData && this.state.userData.loyaltyMemberId) {
                    this.callApiWithUserId(this.state.userData.loyaltyMemberId);
                }
            });
        }

        {
            // Google analytic
            const documentPath = "TH Card"; // Gets the current URL path
            const clientId = getClientId(); // Use the getClientId function

            // Push data to the GTM dataLayer
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'pageview',
                page: documentPath,
                clientId: clientId,
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.cardList !== this.state.cardList && this.state.cardList.length > 0) {
            //this.callApiWithCardId(this.state.cardList[this.state.selectedIndex].cardNumber, this.state.userData.loyaltyMemberId);
            //this.callApiWithCardIdHistory(this.state.cardList[this.state.selectedIndex].cardNumber, this.state.userData.loyaltyMemberId);

            this.callApiWithCardId(
                this.state.cardList[this.state.selectedIndex].cardNumber,
                this.state.userData.loyaltyMemberId
            )
                .then(() => {
                    //console.log("First API call completed, starting the second one...");
                    // Return the second API call
                    return this.callApiWithCardIdHistory(
                        this.state.cardList[this.state.selectedIndex].cardNumber,
                        this.state.userData.loyaltyMemberId
                    );
                })
                .then(() => {
                    // Executes after the second API call finishes
                    //console.log("Both API calls completed");
                })
                .catch((error) => {
                    // Handles errors from either API call
                    console.error("Error during API calls:", error);
                });
        }
    }

    componentWillUnmount() {
        if (this.flickity) {
            this.flickity.destroy();
        }
    }

    async callApiWithUserId(userId) {
        // Generate Unix timestamp
        const timestamp = Math.floor(Date.now() / 1000);

        const payload = { UserId: userId, RefLang: "th", Timestamp: timestamp };
        const encryptedPayload = encryptData(payload);

        const token = this.state.userData.token;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ Data: encryptedPayload })
        };

        try {
            const response = await fetch(`${API_URL}/api/CrmMemberGetCardsList`, requestOptions);
            const data = await response.json();

            if (data && Array.isArray(data)) {
                const filteredOnlyRegistered = data.filter(item => item.cardStatusText === "Registered");
                const sortedCard = filteredOnlyRegistered.sort((a, b) => new Date(b.cardNumber) - new Date(a.cardNumber));

                this.setState({ cardList: data, cardListActive: sortedCard }, () => {
                    // Initialize Flickity after images are loaded
                    if (this.carouselRef.current) {
                        this.flickity = new Flickity(this.carouselRef.current, {
                            cellAlign: 'left',
                            contain: true,
                            imagesLoaded: true, // Wait for images to be loaded
                            prevNextButtons: true,
                            pageDots: true
                        });
                    }
                });
            }
            else {
                console.info('No data:');
            }
        } catch (error) {
            console.error('API call error :', error);
        }
    }

    async callApiWithCardId(cardNumber, userId) {        
        // Generate Unix timestamp
        const timestamp = Math.floor(Date.now() / 1000);

        const payload = { Cardno: cardNumber, RefLang: "th", UserId: userId, Timestamp: timestamp };
        const encryptedPayload = encryptData(payload);

        const token = this.state.userData.token;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ Data: encryptedPayload })
        };

        try {
            // Add the wait cursor class to the body element
            document.body.classList.add('wait-cursor');

            const response = await fetch(`${API_URL}/api/CheckCardBalance`, requestOptions);
            const data = await response.json();
            if (data) {
                this.setState({
                    selectedCard: data,
                    cardNickName: this.state.cardList[this.state.selectedIndex].cardNickName === null ? '' : this.state.cardList[this.state.selectedIndex].cardNickName,
                    cardType: this.state.cardList[this.state.selectedIndex].cardType
                });
            }
        } catch (error) {
            console.error('API call error:', error);
        }
        finally {
            document.body.classList.remove('wait-cursor'); // Remove the wait cursor class
        }
    }

    async callApiWithCardIdHistory(cardNumber, userId) {
        this.setState({ renderCheck: false });

        // Generate Unix timestamp
        const timestamp = Math.floor(Date.now() / 1000);

        const payload = { Cardno: cardNumber, RefLang: "th", UserId: userId, Timestamp: timestamp };
        const encryptedPayload = encryptData(payload);

        const token = this.state.userData.token;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ Data: encryptedPayload })
        };

        try {
            // Add the wait cursor class to the body element
            document.body.classList.add('wait-cursor');

            const response = await fetch(`${API_URL}/api/CheckCardHistory`, requestOptions);
            const data = await response.json();
            if (data) {
                let sortedList = [];

                if (Array.isArray(data.cardHistory)) {
                    if (data.cardHistory[0].requestCode !== 'Error Occurred.') {
                        sortedList = data.cardHistory.sort((a, b) => new Date(b.occurred) - new Date(a.occurred));
                    }
                }

                this.setState({
                    selectedCardHis: sortedList,
                    renderCheck: true
                });
            }
        } catch (error) {
            console.error('API call error:', error);
        }
        finally {
            document.body.classList.remove('wait-cursor'); // Remove the wait cursor class
        }

        this.setState({ renderCheck: true });
    }

    async callApiCardInfoUpdate(loyaltyMemberId, cardNumber, cardStatus, cardNickName, defaultCard, refLang) {
        // Generate Unix timestamp
        const timestamp = Math.floor(Date.now() / 1000);

        const payload = { LoyaltyMemberId: loyaltyMemberId, CardNumber: cardNumber, CardStatus: cardStatus, CardNickName: cardNickName, DefaultCard: defaultCard, RefLang: refLang, Timestamp: timestamp };
        const encryptedPayload = encryptData(payload);

        const token = this.state.userData.token;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ Data: encryptedPayload })
        };

        try {
            // Add the wait cursor class to the body element
            document.body.classList.add('wait-cursor');

            const response = await fetch(`${API_URL}/api/CrmCardUpdate`, requestOptions);
            const data = await response.json();
            if (data && data.responseCode == "OK") {
                this.callApiWithUserId(this.state.userData.loyaltyMemberId);

                if (defaultCard) { //if this one is set defaultCard=true make selectedIndex to the first card
                    this.setState({
                        selectedIndex: 0
                    });
                }
            }
            else {
                //some error here
                console.error('API call success but return error:', data.statusMsg);
            }
        } catch (error) {
            console.error('API call error:', error);
        }
        finally {
            document.body.classList.remove('wait-cursor'); // Remove the wait cursor class
        }
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleCardClick(index) {        
        this.setState({ selectedIndex: index }, () => {
            //check is only 'Registered' to call api
            if (this.state.cardList[this.state.selectedIndex].cardStatusText === "Registered") {
                //this.callApiWithCardId(this.state.cardList[this.state.selectedIndex].cardNumber);
                //this.callApiWithCardIdHistory(this.state.cardList[this.state.selectedIndex].cardNumber);
                this.callApiWithCardId(
                    this.state.cardList[this.state.selectedIndex].cardNumber,
                    this.state.userData.loyaltyMemberId
                )
                    .then(() => {
                        //console.log("First API call completed, starting the second one...");
                        // Return the second API call
                        return this.callApiWithCardIdHistory(
                            this.state.cardList[this.state.selectedIndex].cardNumber,
                            this.state.userData.loyaltyMemberId
                        );
                    })
                    .then(() => {
                        // Executes after the second API call finishes
                        //console.log("Both API calls completed");
                    })
                    .catch((error) => {
                        // Handles errors from either API call
                        console.error("Error during API calls:", error);
                    });
            }
            else {
                this.setState({
                    selectedCard: [],
                    selectedCardHis: [],
                    cardNickName: ''
                });
            }

            // Ensure Flickity instance is available
            if (this.flickity) {
                this.flickity.select(this.state.selectedIndex);
            }
        });
    }

    handleCardUpdateInfo(cardNickName, defaultCard) {
        this.callApiCardInfoUpdate(this.state.userData.loyaltyMemberId, this.state.cardList[this.state.selectedIndex].cardNumber, this.state.cardList[this.state.selectedIndex].cardStatus, cardNickName, defaultCard, "en");
    }

    formatNumber(numberString) {
        // Convert the string to a number
        const number = parseFloat(numberString);
        if (isNaN(number)) {
            return "-";
        }
        else {
            // Use Intl.NumberFormat to format the number with commas
            return number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        }
    }

    render() {
        const { userData, cardList, cardListActive, selectedIndex, selectedCard, selectedCardHis, cardNickName, cardType, renderCheck } = this.state;
        const selectedCardFromlist = cardList[selectedIndex];

        let selectedCardNumber, selectedcardBalance, selectedcardHistory, selectedCardStatus, dateTimeString, selectedCardIsDefault, selectedCardcanReportLost, selectedCardcanUpdateCardStatus;

        let canFuncEdit, canFuncLost, canFuncRemove, canFuncReload, canFuncTransfer; //enable Card Function base on condition
        canFuncEdit = false;
        canFuncLost = false;
        canFuncRemove = false;
        canFuncReload = false;
        canFuncTransfer = false;

        if (renderCheck) {
            //do this only after finish all load
            if (selectedCard) {
                selectedcardBalance = selectedCard.cardBalance;
                selectedcardHistory = selectedCardHis;
                if (selectedCard.dateOccur != null) {
                    dateTimeString = "อัพเดทเมื่อ " + selectedCard.dateOccur;
                }
            }

            if (selectedCardFromlist) {
                selectedCardNumber = selectedCardFromlist.cardNumber;
                selectedCardStatus = selectedCardFromlist.cardStatusText;
                selectedCardIsDefault = selectedCardFromlist.defaultCard ? true : false;
                selectedCardcanReportLost = selectedCardFromlist.canReportLost;
                selectedCardcanUpdateCardStatus = selectedCardFromlist.canUpdateCardStatus;

                if (selectedCardFromlist.cardStatusText === "Registered") {
                    canFuncEdit = true;
                    canFuncLost = true;
                    canFuncRemove = true;
                    canFuncReload = true;
                    canFuncTransfer = true;

                    //Show in TH language
                    selectedCardStatus = "ลงทะเบียน";
                }
                else {
                    canFuncEdit = false;
                    canFuncLost = false;
                    canFuncRemove = false;
                    canFuncReload = false;
                    canFuncTransfer = false;
                }
            }

            if (cardListActive && cardListActive.length === 1) {
                canFuncTransfer = false;
                canFuncRemove = false;
            }
            else {
                canFuncRemove = true;
            }

            if (cardType.startsWith('Virtual') || cardType.startsWith('LINE') || cardType.startsWith('Gold')) {
                canFuncRemove = false;
            }
        }
        

        return (
            <>
                <ThNavHeader />

                {/* Render the user data */}
                {userData && (
                    <main id="main">
                        <section id="profile_section" className="profileBanner keyboardFocus" aria-label="Profile" tabIndex={-1}>
                            <ThUserMenu />
                        </section>
                        <section className="section-dashboard overflow-hidden pb-5">
                            <div className="container mt-4">
                                <div className="row mb-5 px-2">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-8"><h2 className="h4 fw-semibold my-4">บัตรสตาร์บัคส์</h2></div>
                                            <div className="col text-center text-md-end py-3">
                                                <Link to="/thusercardadd" className="btn btn-primary rounded-pill px-4 me-2">เพิ่มบัตรใหม่</Link>
                                                <Link to="/thfaq" className="btn btn-outline-green rounded-pill px-4" target="_blank" rel="noopener noreferrer">คำถามที่พบบ่อย</Link>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                {/*Row Products Slide*/}
                                                <div className="row row-cards-slide">
                                                    <div className="col">
                                                        {/* Flickity HTML init : Product Slide */}
                                                        {cardList.length > 0 ? (
                                                            <div className="flickity-container" ref={this.carouselRef}>
                                                                {cardList.map((card, index) => (
                                                                    <div className="carousel-cell col-cards-slide" key={index} onClick={() => this.handleCardClick(index)}>
                                                                        <div className="col-cards-item">
                                                                            <div className="cards-item-img">
                                                                                <img src={`media/rewards/cards/${card.cardImg}`} alt={card.cardNumber} className="img-fluid" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        ) : (
                                                            <p>{/*No cards available.*/}</p>
                                                        )}
                                                    </div>
                                                </div>
                                                {/*Row Products Slide*/}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="card p-3 p-md-4s rounded-top-0">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <h6 className="fw-semibold mt-3">ข้อมูลบัตร</h6>
                                                            <hr className="mt-3 mb-3 w-75" />
                                                            <h6 className="fw-semibold">
                                                                ({selectedCardNumber}) {selectedCardIsDefault && (<span className="text-green-1">Default</span>)}
                                                            </h6>
                                                            <h6 className="fw-semibold text-black">สถานะ : {selectedCardStatus}</h6>
                                                            <p>
                                                                {canFuncEdit && selectedCardIsDefault === false && (
                                                                    <button type="button" className="btn btn-primary btn-slim rounded-pill px-3 me-2" onClick={() => this.handleCardUpdateInfo(cardNickName, true)}>
                                                                        ตั้งเป็นบัตรหลัก
                                                                    </button>
                                                                )}
                                                            </p>
                                                            <form className="form-sr">
                                                                <div className="row g-3 align-items-center mt-1 mb-4">
                                                                    <div className="col-auto">
                                                                        <label className="col-form-label">ชื่อบัตร</label>
                                                                    </div>
                                                                    <div className="col-auto">
                                                                        <input type="text" className="form-control bg-gray1" value={cardNickName} name="cardNickName" onChange={this.handleChange} />
                                                                    </div>
                                                                    <div className="col-auto">
                                                                        {canFuncEdit && (
                                                                            <button type="button" className="btn btn-outline-green rounded-pill px-3" onClick={() => this.handleCardUpdateInfo(cardNickName, selectedCardIsDefault)}>
                                                                                แก้ไข
                                                                            </button>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <h6 className="fw-semibold">Card Balance</h6>
                                                                <h3 className="fw-semibold text-green-1">฿ {this.formatNumber(selectedcardBalance)}</h3>
                                                                <p className="small">{dateTimeString}</p>
                                                                <p>
                                                                    {canFuncReload && (
                                                                        <Link className="btn btn-primary rounded-pill px-4 me-2"
                                                                            to={{
                                                                                pathname: '/thusercardreload',
                                                                                state: { selectedCard: selectedCard, selectedCardFromlist: selectedCardFromlist }
                                                                            }}
                                                                        >เติมเงินในบัตร</Link>
                                                                    )}
                                                                    {canFuncTransfer && (
                                                                        <Link className="btn btn-primary rounded-pill px-4"
                                                                            to={{
                                                                                pathname: '/thusercardtransfer',
                                                                                state: { selectedCard: selectedCard, selectedCardFromlist: selectedCardFromlist, cardList: cardListActive }
                                                                            }}
                                                                        >โอนเงินระหว่างบัตร</Link>
                                                                    )}
                                                                </p>
                                                                <div className="text-start mt-2">
                                                                    {canFuncLost && selectedCardcanReportLost && (
                                                                        <Link className="btn btn-outline-green rounded-pill px-4"
                                                                            to={{
                                                                                pathname: '/thusercardlost',
                                                                                state: { selectedCard: selectedCard, selectedCardFromlist: selectedCardFromlist }
                                                                            }}
                                                                        >แจ้งบัตรสูญหายหรือถูกขโมย</Link>
                                                                    )}
                                                                </div>
                                                                <div className="text-start mt-2">
                                                                    {canFuncRemove && (
                                                                        <Link className="btn btn-outline-green rounded-pill px-4"
                                                                            to={{
                                                                                pathname: '/thusercardremove',
                                                                                state: { selectedCard: selectedCard, selectedCardFromlist: selectedCardFromlist }
                                                                            }}
                                                                        >ลบบัตร</Link>
                                                                    )}
                                                                </div>
                                                            </form>
                                                        </div>
                                                        {renderCheck ? (
                                                            <>
                                                                <div className="col-md-5">
                                                                    <h6 className="fw-semibold mt-3">ประวัติการทำธุรกรรม</h6>
                                                                    <hr className="mt-3 mb-3" />
                                                                    {selectedcardHistory && selectedcardHistory.length > 0 ? (
                                                                        <div>
                                                                            {selectedcardHistory.map((item, index) => (
                                                                                <div className="row mb-3" key={index}>
                                                                                    <div className="col-4">{item.occurred}</div>
                                                                                    <div className="col-4">{item.requestCode}</div>
                                                                                    <div className="col-4 text-end">{item.amountSign} ฿ {this.formatNumber(item.transactionAmount)}</div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    ) : (
                                                                        <p>ไม่มีประวัติการทำธุรกรรม</p>
                                                                    )}
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {/*Loading*/}
                                                                <div className="col-md-5">
                                                                    <div className="text-center" style={{ height: '160px' }} />
                                                                    <div className="text-center mt-5 pt-5 mb-5 pb-5">
                                                                        <h3 className="text-white">LOADING ...</h3>
                                                                        <img src="media/loading-gif.gif" alt="Loading..." width={80} className="img-fluid" />
                                                                    </div>
                                                                    <div style={{ height: '100px' }} />
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div></div>
                        </section>
                    </main>
                )}

                <ThNavFooter />
            </>
        );
    }
}
