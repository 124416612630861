import React, { Component } from 'react';

import { EnNavHeader } from './EnNavHeader';
import { EnNavFooter } from './EnNavFooter';

export class EnTerm extends Component {
    static displayName = EnTerm.name;

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {

    return (
        <>
            <EnNavHeader />

            <main id="main">
                <section className="section-dashboard overflow-hidden pb-5">
                    <div className="container mt-4">
                        <div className="row px-2">
                            <div className="col-md-12">
                                <h2 className="h4 fw-semibold my-4">Starbucks Card Account Terms of Use and Agreement</h2>
                                <div className="row mt-3 mb-4">
                                    <div className="col-md-12 content-term">
                                        <p><strong>Starbucks  Thailand</strong></p>
                                        <p><strong>Effective August 29, 2024</strong></p>
                                        <p>IMPORTANT -- PLEASE READ: The  following Starbucks Card Agreement describes the terms and conditions that  apply to the stored value account of your Starbucks Card. This agreement is  divided into two parts. Part One describes the terms that apply generally to  the Starbucks Card. Part Two contains important legal terms and conditions.</p>
                                        <p>YOUR USE OF YOUR STARBUCKS CARD IS  GOVERNED BY THE TERMS OF THIS AGREEMENT. PLEASE ENSURE THAT YOU HAVE READ AND  UNDERSTOOD THESE TERMS BEFORE USING YOUR STARBUCKS CARD. IF YOU DO NOT  UNDERSTAND THESE TERMS, YOU MAY CALL 02-339-0996 TO DISCUSS YOUR CONCERNS WITH  US BEFORE YOU FIRST USE YOUR STARBUCKS CARD.</p>
                                        <p>PLEASE ALSO TAKE THE TIME TO REGISTER  YOUR STARBUCKS CARD BY VISITING OUR WEB SITE AT&nbsp;<a href="https://www.starbucks.co.th/" target="_blank"><strong>www.starbucks.co.th</strong></a>. AS THIS WILL  PROVIDE YOU WITH EXTRA SECURITY IN THE EVENT THAT YOUR CARD IS LOST OR STOLEN.</p>
                                        <p>WE  RECOMMEND YOU KEEP A COPY OF THIS AGREEMENT FOR YOUR RECORDS.</p>
                                        <p><strong>PART  ONE: STARBUCKS CARD GENERAL TERMS</strong></p>
                                        <p><u>About  Your Account; Starbucks Purchases Only</u></p>
                                        <p>The Starbucks Card is issued to you by  Starbucks. It allows you to load a monetary value onto your Starbucks Card for  future purchases at participating Starbucks stores in Thailand. The Starbucks  Card is to be treated like cash. No refund is available for the Starbucks Card  except as required by law. It cannot be replaced if lost, stolen or destroyed  unless you have registered it and reported the loss as described below. The  monetary value that you load onto your Starbucks Card is a pre-payment only for  the goods and services of participating Starbucks stores. No credit card,  credit line, overdraft protection or deposit account is associated with a  Starbucks Card. No interest, dividends or any other earnings on funds loaded  onto a Starbucks Card will accrue or be paid or credited to you by Starbucks.  The value associated with the Starbucks Card is not insured or guaranteed by  any person or entity. The monetary value stored on any Starbucks Card may not  be transferred to another Starbucks Card except for transfer to another  Starbucks Card registered under the same Starbucks Card account or for  replacement of a registered Starbucks Card which has been reported to be lost,  stolen or destroyed as described below.</p>
                                        <p>Your Starbucks Card is valid for use  in participating Starbucks stores in Thailand only. We reserve the right not to  accept any Starbucks Card or otherwise limit use of a Starbucks Card if we  reasonably believe that the use is unauthorized, fraudulent or otherwise  unlawful.</p>
                                        <p><strong>Loading  Value on Your Card</strong></p>
                                        <p>In Thailand, you  can only load value onto a Starbucks Card by using a credit card, debit card,  cash, digital wallet,  mobile banking services, or any other payment method that Starbucks may further  facilitate by visiting any participating Starbucks stores, going  online at the website Starbucks.co.th/Card, or using Starbucks TH Mobile app on your mobile phone device (<strong>Android� or IOS</strong>).&nbsp; </p>
                                        <p>The value of your  Starbucks Card is subject to the following load limits: a maximum value of  THB20,000 may be stored on any Starbucks Card at any given time.&nbsp; In case of loading value at Starbucks stores, the minimum amount that can be loaded to any Starbucks  Card is THB100.&nbsp; In case of loading value  through Starbucks website <a href="https://rewards.starbucks.co.th/">https://rewards.starbucks.co.th/</a> &nbsp;or Starbucks TH Mobile app, the minimum amount that can be loaded is THB300. &nbsp;Starbucks may change these maximum and minimum amounts at any time by  notifying you at the point of sale or on the Starbucks website or on Starbucks  TH Mobile app, which such change shall constitute an amendment to this agreement  only for the relevant parts. </p>
                                        <p><strong>Fees  and Expiry Date</strong></p>
                                        <p>Starbucks does not charge any fees for  the issuance, activation, use or reloading of your Starbucks Card. Your  Starbucks Card has no expiration date.</p>
                                        <p><strong>Receipts  and Statements</strong></p>
                                        <p>Cardholders are not sent statements of  itemized transactions from a Starbucks Card account. You can check the balance  of your Starbucks Card or review the last ten recent transactions on your  Starbucks Card on our web site at&nbsp;<a href="https://www.starbucks.co.th/" target="_blank"><strong>www.starbucks.co.th</strong></a>&nbsp;or Starbucks  TH Mobile app or by calling 02-339-0996. When you use your Starbucks Card at a  participating Starbucks store, you will receive a receipt if you request one  but will not be asked to sign the receipt. The receipt will indicate that the  purchase was made using a Starbucks Card and will provide the remaining balance  of your Starbucks Card. You should keep your receipts and check your online  statement to ensure that your account balance is correct.</p>
                                        <p><strong>Redemption</strong></p>
                                        <p>Any amount on your Starbucks Card is  non-refundable and may not be exchanged for cash or credit balance unless  required by law or permitted by the terms of this agreement. All reward  benefits are valid only within their stipulated periods.</p>
                                        <p><strong>Complaint  Handling</strong></p>
                                        <p>We reserve the right to correct the  balance of your Starbucks Card account if we believe that a clerical, billing  or accounting error has occurred. If you have questions regarding your  transaction history or any correction, or if you dispute any transaction or  correction that has been assessed against your Starbucks Card, please contact  our Customer Care Team by phone at 02-339-0996 and we will conduct an  investigation and communicate the results and correct any error that we verify  as soon as we finish the investigation. If no error was found, we will  communicate an explanation. We will have no liability for any billing error  unless you provide us notice within sixty (60) days of the date of the  transaction in question. You should monitor your transactions and account  balances closely.</p>
                                        <p><strong>Optional  Registration; Liability for Unauthorised Transactions</strong></p>
                                        <p>Because your Starbucks Card is used  like cash for purchases from Starbucks, you are responsible for all  transactions associated with your Starbucks Card, including unauthorized  transactions. However, if your Starbucks Card is lost, stolen or destroyed, you  can freeze your Starbucks Card at&nbsp;<a href="https://www.starbucks.co.th/" target="_blank"><strong>www.starbucks.co.th</strong></a>&nbsp;and transfer  the remaining balance to one of your other registered cards in your account  within 30 days. Otherwise please contact our Customer Care Team by phone at  02-339-0996. To register your Starbucks Card, please visit our web site  at&nbsp;<a href="https://www.starbucks.co.th/"><strong>www.starbucks.co.th</strong></a>&nbsp;or  Starbucks TH App. Personal Information which you provide to us for purposes of  registering your Starbucks Card will be held pursuant to Starbucks Privacy  Policy. You may not register a Starbucks Card which was issued or activated  outside Thailand.</p>
                                        <p><strong>PART  TWO: IMPORTANT LEGAL TERMS</strong></p>
                                        <p><strong>Changes  to This Agreement</strong></p>
                                        <p>We may amend the terms of this  agreement at any time for security, legal, regulatory or valid business  reasons. We will post the terms to the modified agreement on our web site  at&nbsp;<a href="https://www.starbucks.co.th/" target="_blank"><strong>www.starbucks.co.th</strong></a>. Subject to the  remainder of this clause, any change, addition or deletion will become  effective at the time we post the revised agreement to our web site or as  otherwise stated in our notice to you. Unless we state otherwise, the change,  addition or deletion will apply to your future and existing Starbucks Cards.  The amended terms will apply to you if (a) you do not notify us in writing to  the contrary within twenty (20) days of the date of our notice (or such other  reasonable period of time as specified in our notice to you), or (b) you use  your Starbucks Card after such twenty (20) day notice period. If you do not  accept the changes, additions or deletions, you should stop using the Starbucks  Card.</p>
                                        <p><strong>Cancellation  of This Agreement</strong></p>
                                        <p>We may suspend or terminate this  agreement at any time by giving you reasonable prior notice. We need not,  however, give you notice where we have serious grounds for considering that  immediate termination is required (e.g. if we reasonably suspect fraudulent or  unauthorized use of the Starbucks Card). If we terminate this agreement in  circumstances where it is determined you are not in breach of this agreement,  we will refund or issue store credits equal to the balance held in your  Starbucks Card account less any amounts that you owe us. In the event that this  agreement is terminated, this Part Two shall continue to apply as between us in  respect of anything either of us did whilst this agreement was in force.</p>
                                        <p><strong>Governing  Law</strong></p>
                                        <p>This  agreement is governed by the laws of Thailand.</p>
                                        <p><strong>Disclaimers  and Limits of Liability</strong></p>
                                        <p>Starbucks does not represent or  warrant that your Starbucks Card will always be accessible or accepted at  participating Starbucks stores.</p>
                                        <p>In the event that Starbucks is held  liable to you under this agreement, you will be entitled to recover from us  your actual damages, up to the last balance held on your Starbucks Card in  question.</p>
                                        <p>Starbucks will not be liable to you  for:</p>
                                        <ul type="disc">
                                            <li>any loss of income, business, goodwill or profits       arising out of this agreement;</li>
                                            <li>any unauthorized access to or alteration, theft or       destruction of your Starbucks Card;</li>
                                            <li>the consequences of any delay or mistake relating       to the use of your Starbucks Card caused by any circumstances beyond our       control;</li>
                                            <li>any loss or damage which was not caused by our       breach of this agreement or breach of our legal duty of care;</li>
                                            <li>any loss or damage which was not a reasonably       foreseeable result of either our breach of this agreement or breach of our       legal duty of care. Loss or damage is "reasonably foreseeable"       if, at the time we entered into this agreement, such loss was contemplated       by you and by us; or</li>
                                            <li>any loss or damage suffered by you as a result of       you failing to take reasonable precautions against such loss or damage.</li>
                                        </ul>
                                        <p>This  agreement does not affect your rights under applicable law.</p>
                                        <p><strong>Assignment</strong></p>
                                        <p>We may transfer  all or part of this agreement without such transfer being considered a change  to the agreement, by giving notice to you, and you hereby agree and consent to  the transfer upon being notified. The company or other entity to whom we  transfer this agreement will have the same rights and obligations as we did  before the transfer and we will ensure that it agrees in writing to be bound by  the terms and conditions of this agreement so that you rights under this  agreement are not detrimentally affected and we will then be released from all  liability.</p>
                                        <p><strong>Entire  Agreement; Construction</strong></p>
                                        <p>This agreement is the complete and  exclusive statement of agreement between you and Coffee Concepts Co., Ltd. and  supersedes all prior proposals and all other agreements in respect of the  subject matter of this agreement. In case of any conflict between the  provisions of the English version of this agreement and those of any other  language version, the English version will prevail. If any provision of this  agreement is illegal or unenforceable, that provision will be deleted from this  agreement, and the remaining terms will not be affected. Headings are for  convenience of reference only and will in no way affect interpretation of this  agreement.</p>
                                        <p><strong>Enquiries  or Questions</strong></p>
                                        <p>If you have any questions regarding  this Starbucks Card Account Terms of Use and Agreement, or your Starbucks Card,  please email our Customer Care Team at: customercomment@coffee-concepts.co.th,  or by phone at 02-339-0996 (Our Thailand Customer Care Team staff will be available  Mondays to Sundays, 9am to 6pm).</p>
                                        <p>Coffee  Concepts Retail Co. Ltd.<br />
                                            11th, 12th floors, Exchange Tower, 388 Sukhumvit Road, Klongtoey, Bangkok 10110  Thailand</p>
                                        <p><strong>Miscellaneous</strong></p>
                                        <p>By accessing our website or utilizing our  services, you hereby consent to receiving communications from us for the  purpose of offering, marketing and providing to you our website, products and  services, as well as making any improvements to any of them.</p>
                                        <p>User of LINE Starbucks Card may change  LINE Starbucks Card Theme on the LINE application platform only. The change of  LINE Starbucks Card Theme will not affect members current information and  balance amount in the LINE Starbucks Card. The Member can purchase Starbucks�  beverages and products by using the balance amount appeared on the LINE  Starbucks Card.</p>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <EnNavFooter />
      </>
    );
  }
}
