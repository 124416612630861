import React, { Component } from 'react';

import { EnNavHeader } from './EnNavHeader';
import { EnNavFooter } from './EnNavFooter';

export class EnTermOfUse extends Component {
    static displayName = EnTermOfUse.name;

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {

    return (
        <>
            <EnNavHeader />

            <main id="main">
                <section className="section-dashboard overflow-hidden pb-5">
                    <div className="container mt-4">
                        <div className="row mb-5 px-2">
                            <div className="col-md-12">
                                <h2 className="h4 fw-semibold my-4">STARBUCKS THAILAND</h2>
                                <div className="row mt-3 mb-4">
                                    <div className="col-md-12 content-term">
                                        <h4><strong>Starbucks® Rewards  Terms and Conditions </strong></h4>
                                        <p>Last Updated 29 August  2024</p>
                                        <p><strong><u>General Terms and  Conditions</u></strong></p>
                                        <p>PLEASE  READ THESE TERMS AND CONDITIONS FOR PARTICIPATING STARBUCKS® REWARDS PROGRAM  (THE "<strong>PROGRAM</strong>" OR "<strong>STARBUCKS® REWARDS</strong>")  CAREFULLY BEFORE PARTICIPATING AND RECEIVING SPECIAL OFFERS FROM SUCH  PROGRAM.&nbsp; BY REGISTERING IN THIS PROGRAM  IN ANY WAY, YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS SET FORTH HEREIN,  AS WELL AS ANY OTHER TERMS REFERENCED HEREIN. IF YOU DO NOT AGREE TO BE BOUND  BY THESE TERMS AND CONDITIONS, PLEASE DO NOT PARTICIPATE IN THIS PROGRAM.</p>
                                        <p>These  Terms and Conditions apply to your access to, and participation in, Starbucks® Rewards &nbsp;which is operated by Coffee  Concepts Retail Co., Ltd. ("<strong>Starbucks</strong>"),  an authorized licensee of Starbucks Coffee International, Inc. in  Thailand. &nbsp;These Terms and Conditions do not alter in any way the terms or conditions of any other contract you may have entered into with Starbucks for products, services or  otherwise. Starbucks reserves the right to change, modify  and/or cancel Starbucks® Rewards program and/or these  Terms and Conditions or any policy, FAQ, or guideline pertaining to Starbucks®  Rewards at any time and in its sole discretion.&nbsp;  Such changes, modifications or cancellations will be  effective immediately upon posting of such changes, modifications or  cancellations on <a href="http://www.starbucks.co.th">www.starbucks.co.th</a>, and you waive any  rights that may have  to receive specific notice of such changes, modifications or cancellations.&nbsp; </p>
                                        <p>Your  participation in Starbucks® Rewards confirms your acceptance of these Terms and Conditions. Therefore,  you should regularly review these Terms and Conditions and any applicable  policies and FAQs to understand the terms and conditions that apply to Starbucks® Rewards if you do not agree to such Terms and Conditions, you must  immediately stop your participation in Starbucks® Rewards.</p>
                                        <p>This  Program is intended to be used  by individual users who are 20 years  of age or older. If you are under this age, You should review these Terms and  Conditions under the supervision of your parent or legal guardian to ensure  that your parent or guardian understands them.</p>
                                        <p>Please read the Privacy  Notice carefully to understand how Starbucks collects,  uses, and discloses information about Starbucks customers, including how to  update or change your personal information. Also, and how  we communicate with you.</p>
                                        <p><strong><u>Starbucks®  Rewards </u></strong><strong><u>Program  Overview </u></strong></p>
                                        <p>Starbucks®  Rewards is offered at the sole discretion of Starbucks, and it is one of the ways Starbucks endeavors  to reward and express gratitude to  our loyal customers for patronizing  at Starbucks Coffee stores. </p>
                                        <p>To  register in Starbucks® Rewards and receive benefits under  this Program, you will first need to  create an account Starbucks® Rewards in Thailand by downloading the Starbucks Thailand mobile app to  your mobile phone (Android™ or IOS)  and register for Starbucks® Rewards or visit <a href="https://rewards.starbucks.co.th">https://rewards.starbucks.co.th</a>.&nbsp; After you successfully create  an account, you will receive a digital Starbucks Card in your membership  account.<br />
                                            <br />
                                            The  registration process may require you to provide certain personal data using  the online registration form, including username (email address),  password, Starbucks Card number and card  security code (CSC), date, month  and year of birth, name, mobile phone number, marketing  preference (optional). All your Starbucks Cards that are activated in Thailand can be registered  for participating Starbucks® Rewards, provided that you may only have 1 account under these Terms and Conditions.<br />
                                            Starbucks®  Rewards are determined  based on the amount of “spending you make via Starbucks Card(s)” &nbsp;and the number of “Stars” you earn through your spending at participating  Starbucks stores in Thailand or through Starbucks Thailand  mobile app with one or more Starbucks Cards that are registered to participate Starbucks® Rewards. </p>
                                        <p>For each  qualified purchase at any participating Starbucks store in Thailand or through Starbucks  Thailand mobile app, you will earn 1  "Star" for every THB 25 spent with your Starbucks  Card that was registered for  participating Starbucks® Rewards, except during limited-time  promotional periods, when Starbucks may offer “Bonus Stars” on featured  products and/or services.</p>
                                        <p>Stars  are for promotional purposes only and have no monetary value.&nbsp; The Stars you earn will be used to upgrade your level of benefits and redeem benefits from Starbucks. You  can use any Starbucks card that registered in Starbucks® Rewards or activate a Starbucks digital  card appeared in Starbucks Thailand &nbsp;mobile app to  earn Stars for your spending. &nbsp;&nbsp;Please note that spending will  only be calculated and Stars will only be earned and  accumulated after such Starbucks Card has been registered in the Starbucks  Thailand mobile app or at https://rewards.starbucks.co.th. Stars and qualified purchases may be accumulated  under the same Starbucks® Rewards account only.</p>
                                        <p>Stars,  qualified purchases, benefits, and rewards cannot  be combined with or transferred to other Starbucks®  Rewards accounts.</p>
                                        <p>Currently, there are 2 levels of benefits in the Starbucks® Rewards program which are Green Level  and Gold Level.&nbsp; The level of  benefits shall be determined by the number of Stars you have  accumulated in your account. &nbsp;Once you  have upgraded your benefits to a certain level in the Starbucks® Rewards,  you will only receive the benefits specified for that level.  However, You must constantly accumulate Stars to maintain your benefit level  as follows:</p>
                                        <ul>
                                            <li>Upon  creating an account, signing up for  Starbucks® Rewards, and activating  and registering Starbucks Card,  you will automatically be registered as a member of Starbucks®  Rewards at the Green Level.</li>
                                            <li>If you earn 300 or more new Stars during each consecutive 12-month period following  your registration date (i.e., the month of registration will be counted as the 1st month), you will be upgraded to the Gold Level.&nbsp; If you earn 1 – 299 new Stars during  any consecutive 12-month period, your Star balance for upgrading  your benefit level will be automatically reset to zero (0)  after such 12th month, and your benefit level will remain Green  Level. You can start accumulating new Stars  for the next 12 months.</li>
                                            <li>To maintain the Gold Level.  You must earn at least 300 new Stars every consecutive 12-month  period following the date you upgrade your benefit level to Gold Level. If you do not earn 300 new Stars in any consecutive 12-month  period, you will be placed in the Green Level  in Starbucks® Rewards, and your Stars balance for  upgrading your benefit level will be automatically reset  to zero (0). If you fail to requalify at Gold Level, your Star balance can be used for redeeming benefits under the conditions of the Green Level.&nbsp; You can start accumulating  new Stars for the next 12 months.</li>
                                        </ul>
                                        <p>Please  note that the “Stars” accumulated for upgrading benefit level will only be valid during the 12-month  period from the date you reach the particular level.&nbsp; For example, you register as a  member of Starbucks® Rewards through Starbucks  Thailand mobile app or on <a href="https://rewards.starbucks.co.th">https://rewards.starbucks.co.th</a> on 20 August 2024, your spending  will only be considered as qualified purchase  from 20 August 2024 when you can start earning  Stars.&nbsp; If you earn 300 Stars or more on 15  October 2024, you will be placed on Gold Level  and entitled to Gold Level benefits. Your  Gold Level benefits will expire on 14 October 2025, and on  15 October 2025, your Star balance for upgrading your benefit level will be reset to zero (0).&nbsp; However, If you have  accumulated 300 Stars or more between 15 October 2024 until 14 October 2025, which is the  accumulation of a certain number of Stars to maintain the  Gold Level, you will continue  to qualify at the Gold Level and be  entitled to various Gold Level benefits for the 12-month period between 15 October 2025 to 14 October  2026.&nbsp; Your Star  balance for upgrading your benefit will be reset  to zero (0) on October 15, 2026. </p>
                                        <p><strong><u>Benefits</u></strong><strong><u> for Green  Level</u></strong></p>
                                        <p>The benefits for the  Green level may  include:</p>
                                        <p>• <strong><u>Use </u></strong><strong><u>accumulated  Stars to redeem benefits</u></strong><u> </u></p>
                                        <p>Stars earned to redeem  benefits will be valid for 6 months starting from the month in which the Stars  were earned. </p>
                                        <ul>
                                            <li><strong><u>Earn </u></strong><strong><u>20 Stars</u></strong><strong>:</strong> You are eligible to  redeem for upsizing one glass of  drink (12 oz. / 16 oz) (except for drinks made from all kinds of Starbucks ReserveTM, juices, ready-to drink  bottled beverages, drinks that are only available in one size, all types of ice cream drinks, and all types of alcoholic beverages) at Starbucks  stores,  Drive Thru service, and purchases through Mobile  Order-ahead &amp; Pick Up at Store, Mobile Order to Table and Starbucks  Delivery services that are available on Starbucks Thailand  mobile app, except for orders through Foodpanda, Grab,  LINE MAN, Robinhood, ShopeeFood and other food delivery  platforms. This benefit cannot be used in conjunction with  other promotions.<br />
                                                <br />
                                            </li>
                                            <li><strong><u>Earn 60 Stars</u></strong><strong>:</strong> You are eligible to redeem  25% discount on one glass of any drink (except  for ready-to drink  bottled beverages that are not Starbucks brand, drinks made from super premium reserved coffee such  as Jamaica Coffee and Blue Mountain Coffee,  all types of ice cream drinks, Coffee Press 8-Cup drink, Flights drink, and all types of alcoholic beverages) or one piece of any type of food (except for mooncake and pound cake) at Starbucks stores,  Drive Thru service, and purchases through Mobile  Order-ahead &amp; Pick Up at Store, Mobile Order to Table and Starbucks  Delivery services that are available on Starbucks Thailand  mobile app, except for orders through Foodpanda, Grab,  LINE MAN, Robinhood, ShopeeFood and other food delivery  platforms. This benefit cannot be used in conjunction with  other promotions.</li>
                                        </ul>
                                        <p><strong><u>Benefit</u></strong><strong><u>s for Gold Level</u></strong></p>
                                        <p>The benefits for the  Gold Level may include:</p>
                                        <p>• <strong><u>Use </u></strong><strong><u>accumulated  Stars to redeem benefits</u></strong><u> </u><u> </u></p>
                                        <p>Stars earned to redeem benefits will be valid  for 6 months, starting from the month in which the Stars were earned. </p>
                                        <ul>
                                            <li><strong><u>Earn </u></strong><strong><u>20 Stars</u></strong><strong>:</strong> You are eligible to  redeem for upsizing one glass of  drink (12 oz. / 16 oz) (except for drinks made from all kinds of Starbucks ReserveTM, juices, ready-to drink  bottled beverages, drinks that are only available in one size, all types of ice cream drinks, and all types of alcoholic beverages) at Starbucks  stores,  Drive Thru service, and purchases through Mobile  Order-ahead &amp; Pick Up at Store, Mobile Order to Table and Starbucks  Delivery services that are available on Starbucks Thailand  mobile app, except for orders through Foodpanda, Grab,  LINE MAN, Robinhood, ShopeeFood and other food delivery  platforms. This benefit cannot be used in conjunction with  other promotions.<br />
                                                <br />
                                            </li>
                                            <li><strong><u>Earn 60 Stars</u></strong><strong>:</strong> You are eligible to redeem  25% discount on one glass of any drink (except  for ready-to drink  bottled beverages that are not Starbucks brand, drinks made from super premium reserved coffee such  as Jamaica Coffee and Blue Mountain Coffee,  all types of ice cream drinks, Coffee Press 8-Cup drink, Flights drink, and all types of alcoholic beverages) or one piece of any type of food (except for mooncake and pound cake) at Starbucks stores,  Drive Thru service, and purchases through Mobile  Order-ahead &amp; Pick Up at Store, Mobile Order to Table and Starbucks  Delivery services that are available on Starbucks Thailand  mobile app, except for orders through Foodpanda, Grab,  LINE MAN, Robinhood, ShopeeFood and other food delivery  platforms. This benefit cannot be used in conjunction with  other promotions.<br />
                                                <br />
                                            </li>
                                            <li><strong><u>Earn 120 Stars</u>:</strong> You are eligible to redeem  one (1) &nbsp;free drink complimentary item in any size with  free 2 customizations (except  for drink /  bottled beverages that are not Starbucks brand, drinks  made from super premium reserved coffee such as Jamaica Coffee and Blue Mountain Coffee, all types of ice cream drinks, Coffee Press 8-Cup drink, Flights drink, and all types of alcoholic beverages /  free 2 customizations except Reserve espresso shot) at Starbucks stores, Drive Thru service, and purchases through Mobile  Order-ahead &amp; Pick Up at Store, Mobile Order to Table and Starbucks  Delivery services that are available on Starbucks Thailand  mobile app, except for orders through Foodpanda, Grab,  LINE MAN, Robinhood, ShopeeFood and other food delivery  platforms. This benefit cannot be used in conjunction with  other promotions.<br />
                                                <br />
                                            </li>
                                            <li><strong><u>Earn 160 Stars</u>:</strong> You are eligible to redeem  THB 200 discount when purchasing all types of Starbucks products having  value of THB 500 or more per one receipt (except  for all types of alcoholic beverages) at Starbucks stores,  Drive Thru service, and purchases through Mobile  Order-ahead &amp; Pick Up at Store, Mobile Order to Table and Starbucks  Delivery services that are available on Starbucks Thailand  mobile app, except for orders through Foodpanda, Grab,  LINE MAN, Robinhood, ShopeeFood and other food delivery  platforms. This benefit is limited to one (1) redemptions per one receipt.&nbsp; &nbsp;This benefit cannot be used in  conjunction with 350 Star-based benefit on the same receipt  and cannot be used in conjunction with other promotions.<br />
                                            </li>
                                            <li><strong><u>Earn 350 Stars</u></strong><strong>:</strong> &nbsp;You are eligible to redeem 4 free drinks  complimentary item in any size with free 2 customizations per  drink (except for drink / bottled beverages that are not  Starbucks brand, drinks made from super premium reserved coffee such as Jamaica Coffee and Blue Mountain Coffee, all types of ice cream  drinks, Coffee  Press 8-Cup drink, Flights drink, and all types of alcoholic beverages /  free 2 customizations except Reserve espresso shot) at Starbucks stores, Drive Thru service, and purchases through Mobile  Order-ahead &amp; Pick Up at Store, Mobile Order to Table and Starbucks  Delivery services that are available on Starbucks Thailand  mobile app, except for orders through Foodpanda, Grab,  LINE MAN, Robinhood, ShopeeFood and other food delivery  platforms.&nbsp; This  benefit is limited to one (1) redemptions per one receipt.&nbsp; &nbsp;This benefit cannot be used in  conjunction with 160 Star-based benefit to redeem  THB 200 discount or 350 Star-based benefit to redeem 50%  discount on the same receipt and cannot be used in conjunction  with other promotions.<br />
                                                <br />
                                            </li>
                                            <li><strong><u>Earn </u></strong><strong><u>350 Stars</u></strong><strong>: </strong>You are eligible to redeem  50% discount (up to THB 600 baht  discount) per receipt  (excluding all types of alcoholic beverages) at Starbucks stores, Drive  Thru service, and purchases through Mobile  Order-ahead &amp; Pick Up at Store, Mobile Order to Table and Starbucks  Delivery services that are available on Starbucks Thailand  mobile app, except for orders through Foodpanda, Grab,  LINE MAN, Robinhood, ShopeeFood and other food delivery  platforms. &nbsp;This benefit is  limited to one (1) redemptions per one receipt.&nbsp; &nbsp;This benefit cannot be used in  conjunction with 160 Star-based benefit to redeem  THB 200 discount or 350 Star-based benefit to redeem 4 free  drinks on the same receipt and cannot be used in conjunction with  other promotions. </li>
                                        </ul>
                                        <p>In case you redeem more than one benefits concurrently, Starbucks reserves the right to select the benefits for you in order of  your highest benefits as follows: </p>
                                        <ul>
                                            <li>350 Stars:&nbsp; Redeem for 4 free drinks  complimentary item in any size with free 2 customizations per  drink on the same receipt.                              </li>
                                            <li>Redeem  birthday reward.</li>
                                            <li>120 Stars: Redeem for 1 complimentary beverage in any size with 2 free  customizations. </li>
                                            <li>60 Stars: Redeem for 25% discount on one glass of any drink or one piece of any food.</li>
                                            <li>20 Stars: Redeem for upsizing one glass of drink (12 oz / 16  oz) </li>
                                            <li>350 Stars: Redeem 50% discount (up to THB  600) per receipts or 160  Stars: Redeem THB 200 discount when  purchasing any types of Starbucks products of THB 500 or more per receipt.</li>
                                        </ul>
                                        <p>• <strong><u>Birthday Reward</u></strong></p>
                                        <p>Gold Level members will receive complementary  items in the month of your birthday, consisting of:</p>
                                        <ul>
                                            <li>1  complimentary beverage in any size with 2 free customizations (except  for drink /  bottled beverages that are not Starbucks brand, Flights drink, and all types of alcoholic beverages) which the price of such  free drink shall not exceed THB 250; and</li>
                                            <li>1 complementary food with the price is not more than THB 150  Baht.</li>
                                        </ul>
                                        <p>You may order  drink or food at a price that exceeds the limitation  specified above by paying the exceeding  price of such drink or food.</p>
                                        <p>Your birthday reward will be automatically provided in your member account, so  that you can use it within your birthday month. You must present your  registered Starbucks Card or Starbucks Card in Starbucks Thailand&nbsp;  mobile app to baristas at participating Starbucks stores  in Thailand to redeem your benefits. You must redeem  the whole set of the complementary items at the same time,  otherwise the unredeemed item will be lapsed. You can redeem your birthday reward by yourself through Mobile Order-ahead &amp;  Pick Up at Store, Mobile Order to Table and Starbucks  Delivery services that are available on Starbucks Thailand  mobile app.&nbsp; You can redeem 1 set of  drink and food as birthday reward per 1 receipt. &nbsp;You can view and track  your available rewards on Starbucks Thailand &nbsp;mobile app  or at <a href="https://rewards.starbucks.co.th">https://rewards.starbucks.co.th</a>.&nbsp; You will only receive one set  of birthday reward regardless of the number of Starbucks Cards that you  have registered to your account.</p>
                                        <p>If you have provided Starbucks with an email  address, you will be  receive a reminder email from Starbucks &nbsp;approximately on the first day of your  birthday (that is, the birthday date of record that you provided in your member  information of Starbucks® Rewards) indicating that your benefit is available to  you. You can check to see if your birthday benefit is available in your  birthday month by by visiting Starbucks Thailand mobile app or at <a href="https://rewards.starbucks.co.th">https://rewards.starbucks.co.th.</a>&nbsp; </p>
                                        <p>• <strong><u>Gold Digital Card</u>:</strong><strong> </strong></p>
                                        <p>You will receive a Digital Gold Card after reaching the Gold Level.</p>
                                        <p>• <strong><u>Additional personalized offers</u></strong><strong>:</strong></p>
                                        <p>Additional personalized  offers may be distributed by email, so you must opt-in to receive promotional  and marketing email offers from Starbucks and provide a valid email in order to  receive them. &nbsp;Additional terms and  conditions may apply.</p>
                                        <p>• <strong><u>Starbucks® Rewards Gift</u></strong><strong> </strong><br />
                                            If you are at the Gold  Level as of 31 December of a year, you will receive Starbucks® Rewards Gift. &nbsp;We will send you more details for collecting  Starbucks® Rewards Gift by email. So, you must opt-in to receive promotional  and marketing email offers from Starbucks and provide a valid email in order to  receive this benefit. &nbsp;You must register to  receive Starbucks® Rewards Gift within the provided period, otherwise it would  be deemed that you waive your right to redeem the gift. You will receive only  one gift regardless of the number of Starbucks Cards that you have registered  to your account. &nbsp;If you do not collect Starbucks®  Rewards Gift within the specified period, it will be deemed that you waive your  right to receive the gift.</p>
                                        <p><strong><u>Deleti</u></strong><strong><u>on of Starbucks®  Rewards Account</u></strong></p>
                                        <p>You may  request to delete your Starbucks® Rewards account at any time via the account  deletion process provided on the Starbucks Website or Starbucks Thailand mobile  app. Please note that (a) all account deletion requests are final and  irreversible once processed; and (b) upon deletion of your account, all  information and benefits associated with your deleted account will not be  accessible or recoverable, including your account history, Starbucks Card  balance (unless stated otherwise), Stars and other rewards or benefits. </p>
                                        <p><strong><u>Miscellaneous</u></strong></p>
                                        <ul>
                                            <li>There  are no membership fees associated with the participation of Starbucks® Rewards.</li>
                                            <li>Starbucks  may introduce promotion programs relating to Starbucks® Rewards which may  result in amendments to these Terms and Conditions in order to consistent with to such programs.</li>
                                            <li>By  accessing the sites or utilizing the services related to the Starbucks® Rewards, you hereby consent to  receive communications from Starbucks  for the purpose of offering, marketing and providing to you our Sites, products  and services, as well as making any improvements to any of them.</li>
                                            <li>Your  Stars, benefits, registered Starbucks Card(s), and your account under the  Starbucks® Rewards are personal to you and may not be sold, transferred or  assigned to, or shared with family, friends or others.</li>
                                            <li>Starbucks  reserves the right to terminate your account and/or your participation in  Starbucks® Rewards if Starbucks determines in its sole discretion that you have  violated these Terms and Conditions, or that the use of your account is unauthorized,  fraudulent or otherwise unlawful.</li>
                                            <li>Starbucks  also reserves the right to “unregister” your member account and/or  participation in Starbucks® Rewards for any member account that has been  inactive for two (2) consecutive years. “<strong>Inactive</strong>” means no purchase  activity through the registered Starbucks Card of such member account. &nbsp;Upon un-registration, you may continue to use  the un-registered Starbucks Card, but will not be entitled to receive any  rewards for such un-registered Starbucks Card. If you wish to receive rewards  for such Starbucks Card under the Starbucks® Rewards, please re-register such  Starbucks Card under other different account through Starbucks  Thailand mobile app or <a href="https://rewards.starbucks.co.th">https://rewards.starbucks.co.th</a></li>
                                            <li>In  case of any conflict between the provisions of the English version and Thai  version of these Terms and Conditions, the Thai version will prevail.</li>
                                            <li>Starbucks  reserves the right to terminate, discontinue, modify or cancel Starbucks®  Rewards at any time and in its sole discretion by posting the revisions on  www.starbucks.co.th without giving specific notice to you.</li>
                                            <li>You  can collect Stars for Starbucks® Rewards when ordering goods through Mobile  Order-ahead &amp; Pick Up at Store, Mobile Order to Table, and Starbucks  Delivery service.&nbsp; However, such services  cannot be combined with any other marketing activities that take place at  Starbucks store unless Starbucks specifies otherwise.</li>
                                            <li>In the event that you use the Mobile Order-ahead  &amp; Pick Up at Store service on Starbucks Thailand mobile  app, Starbucks reserves the right to deliver the goods to you and will not refund the product price to  you, if you fail to collect the goods  within 30 minutes from the time the system has calculated for you based on the  method you selected in your order made through Mobile Order-ahead &amp; Pick Up  at Store service of Starbucks. </li>
                                            <li>Your order on Starbucks Thailand mobile  application will be as confirmed by you through the Mobile Order-ahead  &amp; Pick Up at Store, Mobile Order to Table and Starbucks Delivery services.&nbsp; You will not be  able to modify any order after you have confirmed the order.</li>
                                            <li>Stars  in the Starbucks® Rewards cannot be earned on any purchase of alcoholic  beverages. </li>
                                            <li>If there are any program that links member account  of Starbucks® Rewards with other platforms, the calculations of Stars will be varied depending on  the terms of each platform.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>


            <EnNavFooter />
      </>
    );
  }
}
